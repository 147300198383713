const { API_URL } = require("../../env")

export const createOperationService = (bodyData) => {
    return fetch(`${API_URL}/api-bo/operations/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
}

export const createOperationSharedByChoiceService = (bodyData) => {
    return fetch(`${API_URL}/api-bo/operations/shared-by-choice/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
}

export const createOperationSharedBySplitService = (bodyData) => {
    return fetch(`${API_URL}/api-bo/operations/shared-by-split/create`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bodyData),
    })
}

export const getPaidOperationsByActiveTableService = async (id_table) => {
    try {
      const response = await fetch(`${API_URL}/api-bo/operations/show-paid-by-active-table?id_table=${id_table}`,{
        method: "GET",
      });
      if (!response.ok) {
        throw new Error('Failed to fetch cart items');
      }
      const data = await response.json();
      return data.paidOperationsByActiveTable;
    } catch (error) {
      throw error;
    }
  };