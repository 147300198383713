import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getReviewSettings } from '../common/apiServices/reviews/review.service'
import { addOperationInfos } from '../redux/operationSlice'
import { useDispatch } from 'react-redux'

export default function PaymentSuccess() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        // count 3 seconds Then redirect depends on if reviewStep is Active
        setTimeout(() => {
            // handle localstorage & store changes
            let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
            if(operationInfosStorage){
                const { tableInfos } = operationInfosStorage;

                operationInfosStorage = {
                    ...operationInfosStorage,
                    clientInfos: {},
                    // edit here depends on type_payment (own, shared ...) becasue client 
                    // still needs table shared infos
                    tableInfos: tableInfos
                        ? {
                            id_table: tableInfos.id_table,
                            num_table: tableInfos.num_table,
                        }
                        : {}
                }

                // Save the updated clientInfos back to localStorage
                localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
            }
            // dispatch action to synch redux store
            dispatch(addOperationInfos(operationInfosStorage))

            // call api ti get info if reviewStep active
            getReviewSettings()
                .then((data) => {
                    console.log(data.settings);
                    const {isReviewStepActive} = data.settings[0]
                    if(isReviewStepActive === 'N'){
                        navigate('/review-stars/no/no')
                    }else{
                        // open Modal of review-Start depends on query isReview
                        navigate('/review-stars/yes/yes')
                    }
                })
                .catch((err) => console.log(err))
            // redirect to ome page if reviewStep OFF
        },3000)
    })
  return (
    <div style={{
        background: '#BEF2BE',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }}>
        <div className='text text-center'>
            <img src='/images/payment_success_icon.png' />
            <h1 style={{fontSize: 48, fontWeight: 600, color: '#10C600'}}>
                Paiement réussi 
            </h1>
        </div>
    </div>
  )
}
