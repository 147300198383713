import { useEffect, useState } from 'react'
import { formatPrice, generatePaymentRnd } from '../common/helpers/utils'
import CustomTipModal from './CustomTipModal';
import FormCMI from './payment/FormCMI';
import { paymentRequestService } from '../common/apiServices/payment/payment.service';
import { useDispatch, useSelector } from 'react-redux';
import { addOperationInfos } from '../redux/operationSlice';
import { getOrdersByTableService } from '../common/apiServices/orders/order.service';

export default function PourboireModal({
    setShowPourboireModal,
    calculatedTotal,
    setPourboire,
    handleCreateOperationByRightOption,
    totalAMountByCouponReduction,
    calculateSubTotal
}) {
    const [tipValue, setTipValue] = useState(0);
    const tableInfos = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
    const clientInfosSelector = useSelector((state) => state?.operation?.operationInfos?.clientInfos)
    const dispatch = useDispatch()

    const [paymentDataCmiToSend, setPaymentDataCmiToSend] = useState(null)
    const handlePourboireVal = (val) => {
        setTipValue(val)
        setPourboire(val)
    }
    const cancelPourboire = () => {
        setTipValue(0);
        setPourboire(0);
    }

    const handleConfirmOwnPayAllByPourboire = () => {
        setPourboire(tipValue);        
        // call api paymentRequest to get full paymentData
        const bodyData = {
            amount: calculatedTotal(), 
            autoRedirect: "true",
            oid: tableInfos?.ordersByTable, // send this as array even one order ['oid1'];
            rnd: generatePaymentRnd(),
            tableData: {
                id_table: tableInfos?.id_table,
                num_table: tableInfos?.num_table,
                type_payment_table: tableInfos?.type_payment_table,
                type_shared_payment: tableInfos?.type_shared_payment,
            },
            pourboire: tipValue && Number(tipValue),
            subTotal: formatPrice(calculateSubTotal() - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
            total: calculateSubTotal() + Number(tipValue),
            client_id: clientInfosSelector?.id_client,
        }
        console.log("bodyData :", bodyData)
        
        paymentRequestService(bodyData)
            .then((data) => {
                // getData and send it as props to formCMI to be submitted
                setPaymentDataCmiToSend(data?.paymentCMIData);

                // handle localstorage & store changes
                let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                if(operationInfosStorage){
                    operationInfosStorage = {
                        ...operationInfosStorage,
                        receiptInfos: {
                            paidAmount: formatPrice(calculateSubTotal() + Number(tipValue) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
                            pourboire: tipValue
                        }
                    }

                    // Save the updated clientInfos back to localStorage
                    localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                }
                // dispatch action to synch redux store
                dispatch(addOperationInfos(operationInfosStorage))
            })
            .catch((err) => console.log(err))
    }
    const handleConfirmSharedPayByChoiceByPourboire = () => {
        setPourboire(tipValue);        
        // call api paymentRequest to get full paymentData
        const bodyData = {
            amount: calculatedTotal(), 
            oid: tableInfos?.ordersByTable, // send this as array even one order ['oid1'];
            rnd: generatePaymentRnd(),
            tableData: {
                id_table: tableInfos?.id_table,
                num_table: tableInfos?.num_table,
                type_payment_table: tableInfos?.type_payment_table,
                type_shared_payment: tableInfos?.type_shared_payment,
            },
            pourboire: tipValue && Number(tipValue),
            subTotal: formatPrice(calculateSubTotal()  - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
            total: calculateSubTotal() + Number(tipValue),
            client_id: clientInfosSelector?.id_client,
        }
        console.log("bodyData CHOIcE:", bodyData)

        paymentRequestService(bodyData)
            .then((data) => {
                // getData and send it as props to formCMI to be submitted
                setPaymentDataCmiToSend(data?.paymentCMIData);

                // handle localstorage & store changes
                let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                if(operationInfosStorage){
                    operationInfosStorage = {
                        ...operationInfosStorage,
                        receiptInfos: {
                            paidAmount: formatPrice(calculateSubTotal() + Number(tipValue) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
                            pourboire: tipValue
                        }
                    }

                    // Save the updated clientInfos back to localStorage
                    localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                }
                // dispatch action to synch redux store
                dispatch(addOperationInfos(operationInfosStorage))
            })
            .catch((err) => console.log(err))
    }

    const handleConfirmSharedPayBSplitByPourboire = () => {
        setPourboire(tipValue);        
        // call api paymentRequest to get full paymentData
        const bodyData = {
            amount: calculatedTotal(), 
            oid: tableInfos?.ordersByTable, // send this as array even one order ['oid1'];
            rnd: generatePaymentRnd(),
            tableData: {
                id_table: tableInfos?.id_table,
                num_table: tableInfos?.num_table,
                type_payment_table: tableInfos?.type_payment_table,
                type_shared_payment: tableInfos?.type_shared_payment,
            },
            pourboire: tipValue && Number(tipValue),
            subTotal: formatPrice(calculateSubTotal() - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
            total: calculateSubTotal() + Number(tipValue),
            client_id: clientInfosSelector?.id_client,
        }
        console.log("bodyData SPLIT:", bodyData)

        paymentRequestService(bodyData)
            .then((data) => {
                // getData and send it as props to formCMI to be submitted
                setPaymentDataCmiToSend(data?.paymentCMIData);

                // handle localstorage & store changes
                let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                if(operationInfosStorage){
                    operationInfosStorage = {
                        ...operationInfosStorage,
                        receiptInfos: {
                            paidAmount: formatPrice(calculateSubTotal() + Number(tipValue) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction())),
                            pourboire: tipValue
                        }
                    }

                    // Save the updated clientInfos back to localStorage
                    localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                }
                // dispatch action to synch redux store
                dispatch(addOperationInfos(operationInfosStorage))
            })
            .catch((err) => console.log(err))
    }

    const handlePaymentRequestByRightOption = () => {
        const {type_payment_table, type_shared_payment} = tableInfos;
        if(type_payment_table === "OWN" && type_shared_payment === null){
            handleConfirmOwnPayAllByPourboire();
        }else{
            console.log("it's shared so prepare body of each shared option !")
            if(type_shared_payment === "CHOICE"){
                handleConfirmSharedPayByChoiceByPourboire()
            }else if(type_shared_payment === "SPLIT"){
                handleConfirmSharedPayBSplitByPourboire()
            }else{
                return;
            }
        }
    }

    //  handle state cutom pourboire
    const [showCustomTipModal, setShowCustomTipModal] = useState(false);
    const [ordersListByTable, setOrdersListByTable] = useState([]);

    useEffect(() => {
        // call api get all products of all orders by table where not 'free'
        getOrdersByTableService(tableInfos?.id_table)
            .then((orders) => {
                setOrdersListByTable(orders);
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div class="modal-dialog"
                style={{
                    position: "fixed",
                    bottom: -15,
                    left: "48%",
                    transform: "translateX(-50%)",
                    width: "100%",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                       <div>
                        {/* keep emty to get space busy for flex */}
                       </div>
                        <h5
                            class="modal-title text text-center"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: 25,
                                fontWeight: 600,
                                textAlign:'center'
                            }}
                        >Dites merci à <span style={{color: '#FDD681'}}>l’équipe</span> avec un pourboire</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPourboireModal(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="">
                            <p style={{
                                fontSize: 15,
                                fontWeight: 400,
                                color: '#0000008A'
                            }}><span className='text text-warning'>100%</span> de votre pouboire est reversé à l’équipe du restaurant</p>
                               
                            <div className='tips-options' style={{
                                display: 'flex',
                                alignItems:'center',
                                justifyContent:"space-around"
                            }}>
                                <div style={{
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    height: 80,
                                    width: 122,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 20,
                                    position: 'relative',
                                    cursor:'pointer',
                                    border: tipValue == 5 ? 'solid black 2px' : '',
                                    marginRight: 5
                                }}
                                    onClick={() => handlePourboireVal(5)}
                                >
                                    <p>5 Dhs</p>
                                    <img src='/images/pourboire/Tips5.png' style={{
                                        position: 'absolute',
                                        bottom: -15
                                    }}/>
                                </div>
                                <div style={{
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    height: 80,
                                    width: 122,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 20,
                                    position: 'relative',
                                    cursor:'pointer',
                                    border: tipValue == 10 ? 'solid black 2px' : '',
                                    marginRight: 5
                                }}
                                    onClick={() => handlePourboireVal(10)}
                                >
                                    <p>10 Dhs</p>
                                    <img src='/images/pourboire/Tips10.png' style={{
                                        position: 'absolute',
                                        bottom: -15
                                    }}/>
                                </div>
                                <div style={{
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    height: 80,
                                    width: 122,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 20,
                                    position: 'relative',
                                    cursor:'pointer',
                                    border: tipValue == 15 ? 'solid black 2px' : '',
                                    marginRight: 5
                                }}
                                    onClick={() => handlePourboireVal(15)}
                                >
                                    <p>15 Dhs</p>
                                    <img src='/images/pourboire/Tips15.png' style={{
                                        position: 'absolute',
                                        bottom: -15
                                    }}/>
                                </div>
                            </div> 
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 20
                            }}>
                                <button 
                                 style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    borderRadius: "20px",
                                    border: 'solid black 0.5px',
                                    marginTop: "5px",
                                    padding: "7px",
                                    paddingLeft: "25px",
                                    paddingRight: "25px",
                                    background: "#FFFFFF",
                                    color: '#000000',
                                    marginRight: 2,
                                }}
                                onClick={() => setShowCustomTipModal(true)}

                                >Montant personnalisé</button>
                                <button 
                                 style={{
                                    fontSize: 13,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    borderRadius: "20px",
                                    border: 'none',
                                    marginTop: "5px",
                                    padding: "7px",
                                    paddingLeft: "25px",
                                    paddingRight: "25px",
                                    background: "#C0C0C0",
                                    color: '#FFFFFF',
                                    marginRight: 10,
                                    border: tipValue == 0 ? 'solid black 2px' : 'none',
                                }}
                                onClick={cancelPourboire}
                                >Pas de pourboire</button>
                            </div>
                            {/*Open Custom Pourboire  */}
                            {
                                showCustomTipModal && (
                                    <CustomTipModal
                                        title={"Montant personnalisé"}
                                        setShowCustomTipModal={setShowCustomTipModal}
                                        setPourboire={setPourboire}
                                    />
                                )
                            }
                            {/*END Custom Pourboire  */}
                            <div className='text text-center mt-3'>
                                <p style={{fontSize: 13, fontWeight: 700, fontFamily: 'Poppins'}}>
                                    Votre paiement: {formatPrice(calculatedTotal())} Dhs
                                </p>
                            </div>
                            <button class="" 
                                // type="submit"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    borderRadius: "20px",
                                    padding: "10px",
                                    background: "#000000",
                                    color: '#FFFFFF',
                                    width: 300,
                                    margin: '0 auto'
                                }}
                                onClick={handlePaymentRequestByRightOption}
                            >Payer mon addition </button>
                            {/* CMI FORM TO SUMIT */}
                            <FormCMI 
                               paymentDataCmiToSend={paymentDataCmiToSend && paymentDataCmiToSend} 
                            />
                            {/* CMI FORM TO SUMIT */}
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
