
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addOperationInfos } from "../redux/operationSlice";
import { addSharedByChoiceOptionToAllOrdersByTableService, addSharedBySplitOptionToAllOrdersByTableService, getOrdersByTableService } from "../common/apiServices/orders/order.service";
import { createClientService } from "../common/apiServices/clients/client.service";
import { v4 as uuidv4 } from 'uuid';

export default function SharedOptionsModal({
    title,
    setShowModalOptions,
    setShowCustomPersonsNumberModal,
    setShowSplitPaymentModal
}) {

    const { id_table, type_payment_table, type_shared_payment, numberOfPersons } = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
    const clientInfos = useSelector((state) => state?.operation?.operationInfos?.clientInfos)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // new Code
    const [ordersDataByActiveTable, setOrdersDataByActiveTable] = useState([])
   
    const [selectedSharedTypeByTable, setSelectedSharedTypeByTable] = useState(null);
    
    const [sharedTypeMessage, setSharedTypeMessage]= useState({
        SPLIT: 'Cette table à sélectionné le partage en part égale. Veuillez utiliser cette option pour finaliser votre paiement !',
        CHOICE: 'Cette table à sélectionné le partage par sélection des articles. Veuillez utiliser cette option pour finaliser votre paiement !',
    }) 
    const [isShowSharedTypeMessage, setIsShowSharedTypeMessage] = useState(false);
    const [isConfirmSelectedProductToPay, setIsConfirmSelectedProductToPay] = useState(false);
    const [isConfirmSplittedAmountToPay, setIsConfirmSplittedAmountToPay] = useState(false);


    useEffect(() => {
        // call api to get all order by table which is ON.
        getOrdersByTableService(id_table)
        .then((ordersData) => {
            setOrdersDataByActiveTable(ordersData);
            setSelectedSharedTypeByTable(ordersData[0].type_shared_payment);
            setIsShowSharedTypeMessage(true);
            
            // Check if at least one product in any order has isSelectedToPay: "Y"
            const hasProductSelectedToPay = ordersData.some(order =>
                order.products.some(prod => prod?.isSelectedToPay === "Y")
            );

            setIsConfirmSelectedProductToPay(hasProductSelectedToPay);
            console.log("ordersData[0]?.splitting.length > 0 ", ordersData[0]?.splitting.length > 0)
            setIsConfirmSplittedAmountToPay(ordersData[0]?.splitting.length > 0)
            // set type_payment_table for new joining client becasue he dosn't have this infos in his localstorage
            // it exist only for the who chose the option
            let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
            storageOperationsData = {
                ...storageOperationsData,
                // set in Store type_payment_table & create client_uuid & store response generatedUUId in store
                tableInfos: {
                    ...storageOperationsData.tableInfos,
                    type_payment_table: "SHARED",
                    type_shared_payment: ordersData[0].type_shared_payment,
                    numberOfPersons: ordersData[0]?.numberOfPersons || null ,
                    client_id_inserting: ordersData[0]?.client_id_inserting || null,
                }
            };
            localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
            dispatch(addOperationInfos(storageOperationsData))
        })
        .catch(err => console.log(err))
    }, [id_table, isConfirmSelectedProductToPay, isConfirmSplittedAmountToPay])

    const handleSharedOrdersByChoiceOption = () => {
        if (selectedSharedTypeByTable && selectedSharedTypeByTable === "CHOICE") {
            if (clientInfos?.id_client) {
                // navigate after create client
                navigate('/selected-products-topay');
            } else {
                // create client uuid()
                const bodyData = {
                    client_unique_value: uuidv4(),
                    id_table: id_table,
                }
                // call api service
                createClientService(bodyData)
                    .then((data) => {
                        // store from response the client_unique_value in localStorage and redux
                        let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
                        storageOperationsData = {
                            ...storageOperationsData,
                            clientInfos: {
                                ...storageOperationsData.clientInfos,
                                id_client: data?.client_session_infos?.id_client,
                                client_session_id: data?.client_session_infos?.client_unique_value
                            }
                        };
                        localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
                        dispatch(addOperationInfos(storageOperationsData))

                        // navigate after create client
                        navigate('/selected-products-topay');
                    })
                    .catch((err) => console.log(err))
            }
        } else {
            if (clientInfos?.id_client) {
                // #### START PROCESS ADD SHARED_CHOICE OPTION
                const bodyData = {
                    id_table: id_table,
                    ordersArr: ordersDataByActiveTable.map((item) => item.id_order)
                }
                addSharedByChoiceOptionToAllOrdersByTableService(bodyData)
                    .then(() => {
                        // store from response the client_unique_value in localStorage and redux
                        let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
                        storageOperationsData = {
                            ...storageOperationsData,
                            // set in Store type_payment_table & create client_uuid & store response generatedUUId in store
                            tableInfos: {
                                ...storageOperationsData.tableInfos,
                                type_payment_table: "SHARED",
                                type_shared_payment: "CHOICE",
                            }
                        };
                        localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
                        dispatch(addOperationInfos(storageOperationsData))

                        // close popup
                        setShowModalOptions(false)

                        // redirect to 'selected-cart-items-to-pay'
                        navigate('/selected-products-topay')
                    })
                    .catch((err) => console.log(err))
                // ### END PROCESS
            } else {
                // create client uuid()
                const bodyData = {
                    client_unique_value: uuidv4(),
                    id_table: id_table,
                }
                // call api service
                createClientService(bodyData)
                    .then((data) => {
                        // store from response the client_unique_value in localStorage and redux
                        let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
                        storageOperationsData = {
                            ...storageOperationsData,
                            clientInfos: {
                                ...storageOperationsData.clientInfos,
                                id_client: data?.client_session_infos?.id_client,
                                client_session_id: data?.client_session_infos?.client_unique_value
                            }
                        };
                        localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
                        dispatch(addOperationInfos(storageOperationsData))

                        // call api add shared choice option
                        // #### START PROCESS ADD SHARED_CHOICE OPTION
                        const bodyData = {
                            id_table: id_table,
                            ordersArr: ordersDataByActiveTable.map((item) => item.id_order)
                        }
                        addSharedByChoiceOptionToAllOrdersByTableService(bodyData)
                            .then(() => {
                                // store from response the client_unique_value in localStorage and redux
                                let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
                                storageOperationsData = {
                                    ...storageOperationsData,
                                    // set in Store type_payment_table & create client_uuid & store response generatedUUId in store
                                    tableInfos: {
                                        ...storageOperationsData.tableInfos,
                                        type_payment_table: "SHARED",
                                        type_shared_payment: "CHOICE",
                                    }
                                };
                                localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
                                dispatch(addOperationInfos(storageOperationsData))

                                // close popup
                                setShowModalOptions(false)

                                // redirect to 'selected-cart-items-to-pay'
                                navigate('/selected-products-topay')
                            })
                            .catch((err) => console.log(err))
                        // ### END PROCESS
                    })
                    .catch((err) => console.log(err))
            }
        }
    } 

    const handleSharedCartItemsBySplitOption = () => {
        if(clientInfos?.id_client){
            if(type_payment_table === "SHARED" && type_shared_payment === "SPLIT" && numberOfPersons){
                // navigate to Split Popup directly
                setShowSplitPaymentModal(true);
                return; // Exit early if navigating to modal
            }else{
                // call api to add split option to all these orders by table
                // #### START PROCESS ADD SHARED_SPLIT OPTION
                const bodyData = {
                    id_table: id_table,
                    ordersArr: ordersDataByActiveTable.map((item) => item.id_order)
                }
                addSharedBySplitOptionToAllOrdersByTableService(bodyData)
                    .then((data) => {
                        if (data.code === 1) {
                            setShowModalOptions(false);
                            setShowCustomPersonsNumberModal(true);
                        }
                    })
                    .catch((err) => console.log(err))
                // #### START PROCESS ADD SHARED_SPLIT OPTION
            }
        }else{
            // create client uuid()
            const bodyData = {
                client_unique_value: uuidv4(),
                id_table: id_table,
            }
            // call api service
            createClientService(bodyData)
                .then((data) => {
                    // store from response the client_unique_value in localStorage and redux
                    let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
                    storageOperationsData = {
                        ...storageOperationsData,
                        clientInfos: {
                            ...storageOperationsData.clientInfos,
                            id_client: data?.client_session_infos?.id_client,
                            client_session_id: data?.client_session_infos?.client_unique_value
                        }
                    };
                    localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
                    dispatch(addOperationInfos(storageOperationsData))

                    // verify if shared spli & number persons already defined
                    if(type_payment_table === "SHARED" && type_shared_payment === "SPLIT" && numberOfPersons){
                        // navigate to Split Popup directly
                        setShowSplitPaymentModal(true);
                        return; // Exit early if navigating to modal
                    }else{
                        // call api to add split option to all these orders by table
                        // #### START PROCESS ADD SHARED_SPLIT OPTION
                        const bodyData = {
                            id_table: id_table,
                            ordersArr: ordersDataByActiveTable.map((item) => item.id_order)
                        }
                        addSharedBySplitOptionToAllOrdersByTableService(bodyData)
                            .then((data) => {
                                if (data.code === 1) {
                                    setShowModalOptions(false);
                                    setShowCustomPersonsNumberModal(true);
                                }
                            })
                            .catch((err) => console.log(err))
                        // #### START PROCESS ADD SHARED_SPLIT OPTION
                    }
                })
                .catch((err) => console.log(err))
        }
    }

    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div class="modal-dialog"
                style={{
                    position: "fixed",
                    bottom: -15,
                    left: "49%",
                    transform: "translateX(-50%)",
                    width: "100%",
                }}
            // style={{ position: "fixed", bottom: 0, left: 0, margin: 0, }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title text tex-center"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "22px",
                                fontWeight: "600",
                            }}
                        >{title}</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowModalOptions(false)}
                        />
                    </div>
                    <div class="modal-body">
                        {selectedSharedTypeByTable && isShowSharedTypeMessage 
                            && (isConfirmSelectedProductToPay || isConfirmSplittedAmountToPay) 
                            && (
                                <div class="alert alert-secondary"
                                onClick={() => setIsShowSharedTypeMessage(false)}
                                >
                                    {sharedTypeMessage[selectedSharedTypeByTable]}
                                </div>
                            )}
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary" type="button"
                                style={{
                                    fontSize: "15px",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "32px",
                                    background: "white",
                                    border: " solid black 1px",
                                    marginTop: "5px",
                                    padding: "10px",
                                    borderColor: "white",
                                    background:"linear-gradient(to right, #FCC546, #FDD681)",
                                    color: '#1E1E1E',
                                }}
                                onClick={handleSharedOrdersByChoiceOption}
                                disabled={selectedSharedTypeByTable && selectedSharedTypeByTable === 'SPLIT' && isConfirmSplittedAmountToPay}
                            >Choisir les articles à payer </button>
                            <button class="btn btn-primary" type="button"
                                style={{
                                    fontSize: "15px",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "32px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    background: "white",
                                    border: " solid black 1px",
                                    marginTop: "5px",
                                    padding: "10px",
                                    color: "black"
                                }}
                                onClick={handleSharedCartItemsBySplitOption}
                                disabled={selectedSharedTypeByTable && selectedSharedTypeByTable === 'CHOICE' && isConfirmSelectedProductToPay}
                            >Diviser en part égales</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}