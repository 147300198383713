import { useEffect, useRef } from 'react'

const FormCMI = ({ 
    paymentDataCmiToSend
}) => {
    const formRef = useRef(null);

    useEffect(() => {
        if (paymentDataCmiToSend) {
            console.log('paymentDataCmiToSend submit formCmi :', paymentDataCmiToSend)
            formRef.current.click();
        }
    }, [paymentDataCmiToSend])

    return (
        <div>
            <form method="post" action={paymentDataCmiToSend?.cmi_url}>
                <input type="hidden" name="clientid" value={paymentDataCmiToSend?.clientid} />
                <input type="hidden" name="currency" value={paymentDataCmiToSend?.currency} />
                <input type="hidden" name="lang" value={paymentDataCmiToSend?.lang} />
                <input type="hidden" name="trantype" value={paymentDataCmiToSend?.trantype} />
                <input type="hidden" name="storetype" value={paymentDataCmiToSend?.storetype} />
                <input type="hidden" name="amount" value={paymentDataCmiToSend?.amount} />
                <input type="hidden" name="hash" value={paymentDataCmiToSend?.hash} />
                <input type="hidden" name="hashAlgorithm" value={paymentDataCmiToSend?.hashAlgorithm} />
                <input type="hidden" name="oid" value={paymentDataCmiToSend?.oid} />
                <input type="hidden" name="rnd" value={paymentDataCmiToSend?.rnd} />
                <input type="hidden" name="okUrl" value={paymentDataCmiToSend?.okUrl} />
                <input type="hidden" name="AutoRedirect" value={paymentDataCmiToSend?.autoRedirect} />
                <input type="hidden" name="failUrl" value={paymentDataCmiToSend?.failUrl} />
                <input type="hidden" name="callbackUrl" value={paymentDataCmiToSend?.callbackUrl} />
                {/* <input type="hidden" name="billToName" value={paymentDataCmiToSend?.billToName} /> */}
                {/* <input type="hidden" name="email" value={paymentDataCmiToSend?.email} /> */}
                <input type="submit" ref={formRef} value="submit" style={{ visibility: 'hidden' }} />
            </form>
        </div>
    )
}
export default FormCMI;