import { useNavigate } from 'react-router-dom'

export default function PaymentCMIFail() {
    const navigate = useNavigate()

  return (
    <div style={{
        background: '#ffb09c',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }}>
        <div className='text text-center'>
            <img src='/images/payment_success_icon.png' />
            <h1 style={{fontSize: 48, fontWeight: 600, color: '#ee2400'}}>
                Paiement échoué 
            </h1>
            <button className='btn btn-secondary mt-2'
                    onClick={() => navigate('/payment-process')}
            >Réssayer</button>
        </div>
    </div>
  )
}
