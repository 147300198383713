import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import SubHeader from '../common/components/SubHeader'
import CategoriesList from './CategoriesList'
import MostPopularProducts from './MostPopularProducts'
import { getActiveMenuByDateTimeWithCategoriesByIdService, getMostPopularProductsByActiveMenuService } from '../common/apiServices/menu/menu.service'
import { getCurrentDateTimeVisibility } from '../common/helpers/utils'

export default function FoodMenu() {
    const [activeMenuWithCategories, setActiveMenuWithCategories] = useState([])
    const [mostPopularProductsByActiveMenu, setMostPopularProductsByActiveMenu] = useState([])

    useEffect(() => {
       // call api get Active Menu WIth Categories By current DateTime
        getActiveMenuByDateTimeWithCategoriesByIdService({
            currentDay: getCurrentDateTimeVisibility().day,
            currentTime: getCurrentDateTimeVisibility().time,
            active_status_id: 1
        })
        .then((activeMenuWithCategories) => setActiveMenuWithCategories(activeMenuWithCategories))
        .catch((err) => console.log(err))

    },[])

    useEffect(() => {
        // api getMostPopular By Menu
        if(activeMenuWithCategories && activeMenuWithCategories?.id_menu){
            getMostPopularProductsByActiveMenuService(activeMenuWithCategories?.id_menu)
            .then((mostPopularProducts) => setMostPopularProductsByActiveMenu(mostPopularProducts))
            .catch((err) => console.log(err))
        }
    },[activeMenuWithCategories?.id_menu])

    return (
        <div style={{ height: '600px', backgroundColor: '#f7f6fa' }}>
            <Header/>
            <SubHeader />
            <CategoriesList categories={activeMenuWithCategories?.categories} activeMenu={activeMenuWithCategories}/>
            <MostPopularProducts mostPopularProducts={mostPopularProductsByActiveMenu} />
        </div>
    )
}
