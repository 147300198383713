import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import { useParams } from 'react-router-dom'
import GenericSingleProduct from '../common/components/GenericSingleProduct';
import SubHeader from '../common/components/SubHeader';
import { getOneProduct } from '../common/apiServices/productApiCall';

export default function Product() {

    const {id} = useParams();
    const [currentProduct, setCurrentProduct] = useState({});
    
    useEffect(() => {
        getOneProduct(id)
        .then((prod) => setCurrentProduct(prod))
        .catch((err) => console.log(err))
    },[id])

  return (
    <div className='container-fluid' style={{ height: "100%", backgroundColor: '#f7f6fa' }}>
      <Header />
      <SubHeader />
      <div className='container' style={{display:'flex', justifyContent:'center',marginTop:"20px"}}>
        <GenericSingleProduct product={currentProduct} /> 
      </div>
    </div>
  )
}
