import { API_URL } from "../../../env"

export const paymentRequestService = (bodyObj) => {
    console.log("bodyObj :",bodyObj)
    return fetch(`${API_URL}/api/payments/request`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj)
    })
    .then(res => res.json())
    .catch(err => err)
}