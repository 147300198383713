import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addToCart, decrementQnt, incrementQnt, removeFromCart } from '../../redux/cartSlice';
import { API_URL } from '../../env';
import { showTotalPlusProductSuppsAndSuggsAndPacks } from '../helpers/utils';

export default function GenericListProducts({product, isCartPage}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [active, setActive] = useState(false);

    const addProductToCart = (prod, quant) => {
        const product = { ...prod, quantity: quant };
        let storageCart = JSON.parse(localStorage.getItem('cart')) || { products: [], count: 0 };

        // Ensure storageCart.products is an array
        storageCart.products = storageCart.products || [];

        let foundProduct = storageCart.products.find((item) => item.id === product.id);
      
        if (foundProduct) {
          // If the product is found, update its quantity
          foundProduct.quantity += quant;
          storageCart = { ...storageCart, count: storageCart.count + quant };
        } else {
          // If the product is not found, add it to the cart
          storageCart = {
            ...storageCart,
            products: [...storageCart.products, product],
            count: storageCart.count + quant,
          };
        }
      
        // Save the updated cart state to localStorage
        localStorage.setItem('cart', JSON.stringify(storageCart));
        dispatch(addToCart(storageCart));

        setActive(true);

        // Reset the effect after 1 second
        setTimeout(() => setActive(false), 500);
    };
    
    const decrementQuantity = (prod, quant) => {
        let storageCart = JSON.parse(localStorage.getItem('cart'));
        let foundProduct = storageCart.products.find((item) => item.id === prod.id);
        if(foundProduct){
            if(foundProduct.quantity > 0 && storageCart.count > 0 ){
                foundProduct.quantity = foundProduct.quantity - quant;
                storageCart = {...storageCart, count: storageCart.count - quant};
                if(foundProduct.quantity === 0){
                  // delete from localstorage and dispatch remove from cart
                  let filtredArrRemoveProduct = storageCart.products.filter((item) => item.id !== foundProduct.id)
                  storageCart = {...storageCart, products:filtredArrRemoveProduct}
                  localStorage.setItem('cart', JSON.stringify({...storageCart}));
                  dispatch(removeFromCart(storageCart))
                }
            }
            // setItem again after change in localStorage 
            localStorage.setItem('cart', JSON.stringify({...storageCart}));
            dispatch(decrementQnt(storageCart))
        }
    }
      
    const incrementQuantity = (prod, quant) => {
        let storageCart = JSON.parse(localStorage.getItem('cart'));
        let foundProduct = storageCart.products.find((item) => item.id === prod.id);
        if(foundProduct){
            foundProduct.quantity = foundProduct.quantity + quant;
            storageCart = {...storageCart, count: storageCart.count + quant};
            // setItem again after change in localStorage 
            localStorage.setItem('cart', JSON.stringify({...storageCart}));
            dispatch(incrementQnt(storageCart))
        }
    }

    return (
            <div className="mt-4 mb-3 p-3 shadow-lg bg-body-tertiary rounded" 
                style={{
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center",
                    cursor:'pointer'
                }} 
                onClick={() => navigate(`/products/${product.id}`)}
            >
                <div className="text">
                  <img src={`${API_URL}/images/${product.image}`} className="" alt="..." />
                </div>
                <div className="" style={{width:"100%", padding:0}}>
                    <div className="" style={{paddingLeft:"10px", display:'flex',flexDirection:'column', justifyContent:'center'}}>
                        <h5 className="prod-name"
                            style={{
                                fontFamily:"Poppins",
                                fontSize: '15px',
                        }}
                        >
                            {product.name}
                        </h5>
                        <p className="prod-description" 
                           style={{
                                    fontFamily:"Poppins",
                                    fontSize:"11px",
                                    fontWeight:"400px"
                                }}
                        >
                            {product.description}
                        </p>
                        <div 
                            style={{
                                    display:"flex",
                                    justifyContent:"space-between",
                                    cursor:'pointer'
                                }}
                            onClick={() => navigate(`/products/${product.id}`)}
                        >
                          <p className="text">
                            <strong>{product.price} Dhs</strong>
                            <span class="badge text-bg-warning" style={{marginLeft: '7px'}}>
                                {/* { isCartPage && (countTotalSupplementsOfSingleProducts(product)
                                    ? `+${countTotalSupplementsOfSingleProducts(product)}`
                                    : null)
                                } */}
                                { isCartPage && (showTotalPlusProductSuppsAndSuggsAndPacks(product) > 0
                                    ? `+${showTotalPlusProductSuppsAndSuggsAndPacks(product)}`
                                    : null)
                                }
                            </span>
                          </p>
                          {
                            isCartPage ? (
                                <div style={{ 
                                    display: "flex", 
                                    alignItems:"center"
                                }}
                            >
                                <div 
                                    style = {{marginLeft:'5px', marginRight:'5px'}}
                                    onClick={(event) => {
                                        event.stopPropagation(); 
                                        decrementQuantity(product, 1);
                                    }}
                                >
                                    <img src="/images/icon_decrement.svg" width={30} height={30}/>
                                </div>
                                <div style={{marginLeft:'5px', marginRight:'5px'}}>
                                    <h5>{product.quantity}</h5>
                                </div>
                                <div 
                                    style = {{marginLeft:'5px', marginRight:'5px'}}
                                    onClick={(event) => {
                                        event.stopPropagation(); 
                                        incrementQuantity(product, 1);
                                    }}
                                >
                                    <img src="/images/icon_increment.svg" width={29} height={29}/>
                                </div>
                              </div>
                            )
                            :
                            (
                            <div 
                                // style = {{marginLeft:'5px', marginRight:'5px'}}
                                style={{
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    display: 'inline-block',
                                    padding: '10px', // Adjust padding for better effect
                                    borderRadius: '50%',
                                    backgroundColor: active ? '#FFC300' : 'transparent', // Yellow background on click
                                    transition: 'all 0.1s ease-in',
                                    transform: active ? 'scale(1)' : 'scale(1)', // Enlarges the icon
                                }}
                                onClick={(event) => {
                                    event.stopPropagation(); 
                                    addProductToCart(product, 1);
                                }}
                             >
                                <img src="/images/icon_add.svg" height={30} width={30}/>
                            </div>
                         )
                        }
                        </div>
                    </div>
                </div>
            </div>
    )
}
